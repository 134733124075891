import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Table, Thead, Tbody, Tfoot, Tr, Th, Td, Badge, Tooltip, Checkbox, Button } from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import { faSlidersH, faChartLine, faFileExcel, faPencilRuler, faPrint, faPlus, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from '../../constant';
import { gql, useLazyQuery } from '@apollo/client';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import './style.css';
import { useLocation, useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import useStore from '../../store';
import LoadingTable from '../../components/Table/Loading';
import datePickerFromToToDate from '../../utils/datePickerFromToToDate';
import { Link } from 'react-router-dom';
import LeaveRequestFilter from '../../components/Filter/LeaveRequestFilter';
import LeaveReportModal from '../../components/Modal/LeaveReportModal';
import PrintLayout from '../../components/Print/PrintLayout';
import DownloadAndViewButton from '../../components/Files/DownloadAndViewButton';

const leftLabelWidth = '175px';
const marginTopRow = 1;
const paddingTopRow = 1;
const paddingBottomRow = 1;
const QUERY_DATA = gql`
	query getCompanySubmissionReports(
		$limit: Float
		$offset: Float
		$number: Float
		$name: String
		$fromDate: DateTime
		$toDate: DateTime
		$type: TypeOfLeave
		$read: Boolean
		$status: String
	) {
		getLeaves(
			pagerInput: { limit: $limit, offset: $offset }
			number: $number
			name: $name
			fromDate: $fromDate
			toDate: $toDate
			type: $type
			read: $read
			status: $status
		) {
			leaves {
				id
				purpose
				no
				amount
				createdAt
				type
				from
				to
				comeback
				approveByOffice
				approveByOfficeBy
				approveByOfficeAt
				approveByDepartment
				approveByDepartmentBy
				approveByDepartmentAt
				approveByDirectorGeneral
				approveByDirectorGeneralBy
				approveByDirectorGeneralAt
				attachmentFile
				attachmentFileSize
				user {
					username
					fullName
					fullNameKhmer
					department
				}
				userOffice {
					username
					fullName
					fullNameKhmer
				}
				userDepartment {
					username
					fullName
					fullNameKhmer
				}
				userDG {
					username
					fullName
					fullNameKhmer
				}
			}
			totalPages
			totalRows
		}
	}
`;

const QUERY_COUNT = gql`
	query {
		countUnreadLeaveRequest
	}
`;

function Report() {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const currentPage = 1;
	const [rowLimit, setRowLimit] = useState(1000);
	const number = urlParams.get('number') ? urlParams.get('number') : null;
	const name = urlParams.get('name') ? urlParams.get('name') : null;
	const type = urlParams.get('type') ? urlParams.get('type') : null;
	const fromDate = urlParams.get('fromDate') ? urlParams.get('fromDate') : null;
	const toDate = urlParams.get('toDate') ? urlParams.get('toDate') : null;
	const read = urlParams.get('read') ? urlParams.get('read') : null;
	const status = urlParams.get('status') ? urlParams.get('status') : 'irc-leave-report';

	const { currentLang } = useStore((state) => state.currentLang);
	const { currentUser } = useStore((state) => state.currentUser);
	const [loadCount, { data: countData }] = useLazyQuery(QUERY_COUNT);
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');
	const [loadData, { error, loading, data }] = useLazyQuery(QUERY_DATA);

	useEffect(() => {
		if (number || name || fromDate || toDate || type || read) {
			window.scrollTo(0, 0);
			loadData({
				variables: {
					offset: currentPage ? (currentPage - 1) * rowLimit : 0,
					limit: rowLimit,
					number: parseInt(number),
					name,
					fromDate: datePickerFromToToDate(fromDate),
					toDate: datePickerFromToToDate(toDate),
					type,
					read: read === 'false' ? false : read === 'true' ? true : null,
					status: status ? status : 'in progress',
				},
			});
		}
	}, [number, name, fromDate, toDate, type, read]);

	const [openFilter, setOpenFilter] = useState(false);
	const [openLeaveReportModal, setOpenLeaveReportModal] = useState(false);

	function getDepartmentName(dp) {
		let label = '';
		c.departments.map((item) => {
			if (item.value === dp) {
				label = item.label;
			}
		});
		return label;
	}

	function getLeaveType(type) {
		let res = '';
		leaveType.map((item) => {
			if (item.value === type) {
				res = item.label;
			}
		});
		return res;
	}

	function handlePageClick(data) {
		let selected = data.selected;
		const page = selected + 1;

		let url = `?page=${page}`;

		if (status) {
			url += `&status=${status}`;
		}
		if (number) {
			url += `&number=${number}`;
		}
		if (name) {
			url += `&name=${name}`;
		}

		if (fromDate) {
			url += `&fromDate=${fromDate}`;
		}
		if (toDate) {
			url += `&toDate=${toDate}`;
		}
		if (type) {
			url += `&type=${type}`;
		}
		if (read !== null) {
			const unreadOnly = read ? read : '';
			if (unreadOnly) {
				url += `&read=false`;
			}
		}
		history.push(`/request-leave/pdf-report${url}`);
	}

	function getApproveStatus(approve, date, user) {
		let res = null;
		if (approve === null) {
			res = <Badge colorScheme="yellow">{t('In progress')}</Badge>;
		} else if (approve === true) {
			res = <Badge colorScheme="green">{t('Approved Leave')}</Badge>;
		} else {
			res = <Badge colorScheme="red">{t('Rejected Leave')}</Badge>;
		}
		if (date) {
			res = <Tooltip label={`${moment(date).format('LLLL')} ${t('By')} ${getDisplayName(user)}`}>{res}</Tooltip>;
		}
		return res;
	}

	function callbackFilter(e) {
		history.push(`/request-leave/pdf-report${e}`);
	}

	function getDisplayName(user) {
		let displayName = user?.username;
		if (currentLang === 'kh') {
			displayName = user?.fullNameKhmer ? user?.fullNameKhmer : user?.fullName ? user?.fullName : user?.username;
		} else {
			displayName = user?.fullName ? user?.fullName : user?.fullNameKhmer ? user?.fullNameKhmer : user?.username;
		}
		return displayName;
	}

	function checkIfDeputyDirector(department) {
		const dp = department?.split(',');
		if (dp?.length > 1) {
			return true;
		} else {
			return false;
		}
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Request Leave',
							path: '/request-leave',
						},
						{
							name: 'Export',
							path: '#',
						},
					]}
				/>
				<Flex className="hidden-print">
					<Button
						mt="4"
						ml="4"
						onClick={() => setOpenFilter(true)}
						leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
						colorScheme={`${number || name || fromDate || toDate || read || type ? 'yellow' : 'gray'}`}
						variant="solid"
						size="sm"
						borderRadius={c.borderRadius}
						border="1px solid #bdc3c7}"
					>
						{t('Filter')}
					</Button>
					<Button
						mt="4"
						ml="4"
						onClick={() => window?.print()}
						leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
						colorScheme="gray"
						variant="solid"
						size="sm"
						borderRadius={c.borderRadius}
						border="1px solid #bdc3c7"
					>
						{t('Export')}
					</Button>
				</Flex>

				<>
					{(number || name || fromDate || toDate || type || read) && (
						<>
							{data?.getLeaves?.leaves?.map((item, index) => {
								return (
									<PrintLayout>
										<>
											<Container maxW="container.xl" mt="16px" mb="16px">
												<Center mt="5">
													<Box
														w="100%"
														bg="white"
														borderRadius={c.borderRadius}
														border="1px solid #dbdbdb"
														boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
														pb="32px"
													>
														<Flex
															alignItems="center"
															justifyContent="space-between"
															bg="#FAFAFA"
															p="16px"
															pt="8px"
															pb="8px"
															mb="16px"
															borderBottom="1px solid #dbdbdb"
														>
															<Flex justifyContent="center" alignItems="center">
																<Text fontSize="x-large" fontWeight="bold" mr="4">
																	{t('Request Leave')}
																</Text>
															</Flex>
														</Flex>
														<Box p="16px" className="leave-detail-container">
															<Flex pt={paddingTopRow} pb={paddingBottomRow}>
																<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
																	{t('Number')}
																</Text>
																<Text ml="4">{item?.no}</Text>
															</Flex>
															<Flex pt={paddingTopRow} pb={paddingBottomRow}>
																<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
																	{t('Department')}
																</Text>
																<Text ml="4">{t(getDepartmentName(item?.user?.department))}</Text>
															</Flex>

															<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
																<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
																	{t('Name')}
																</Text>
																<Text ml="4">{getDisplayName(item?.user)}</Text>
															</Flex>
															<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
																<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
																	{t('Type')}
																</Text>
																<Text ml="4">{t(getLeaveType(item?.type))}</Text>
															</Flex>
															<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
																<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
																	{t('Created At')}
																</Text>
																<Text ml="4">{moment(item?.createdAt).format('LLLL')}</Text>
															</Flex>
															<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
																<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
																	{t('Leave amount (Days)')}
																</Text>
																<Text ml="4">{item?.amount}</Text>
															</Flex>
															<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
																<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
																	{t('Leave from date')}
																</Text>
																<Text ml="4">{moment(item?.from).format('LLLL')}</Text>
															</Flex>
															<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
																<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
																	{t('Leave to date')}
																</Text>
																<Text ml="4">{moment(item?.to).format('LLLL')}</Text>
															</Flex>
															<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
																<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
																	{t('Leave comeback date')}
																</Text>
																<Text ml="4">{moment(item?.comeback).format('LLLL')}</Text>
															</Flex>

															<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
																<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
																	{t('Purpose')}
																</Text>
																<Text ml="4">
																	<pre
																		style={{
																			whiteSpace: 'pre-wrap',
																			paddingBottom: 16,
																			fontSize: 14,
																			fontFamily: 'Rajdhani, Hanuman, sans-serif, Moul !important',
																		}}
																	>
																		{item?.purpose}
																	</pre>
																</Text>
															</Flex>

															{item?.attachmentFile && (
																<Flex alignItems="center" mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
																	<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
																		{t('Attachment File')}
																	</Text>
																	<DownloadAndViewButton url={item?.attachmentFile} size={item?.attachmentFileSize} />
																</Flex>
															)}

															{checkIfDeputyDirector(item?.user?.department) ? null : (
																<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
																	<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
																		{t('Approved By Office')}
																	</Text>
																	<Box ml="4">
																		{getApproveStatus(item?.approveByOffice, item?.approveByOfficeAt, item?.userOffice)}
																	</Box>
																</Flex>
															)}

															{checkIfDeputyDirector(item?.user?.department) ? null : (
																<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
																	<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
																		{t('Approved By Department')}
																	</Text>
																	<Box ml="4">
																		{getApproveStatus(
																			item?.approveByDepartment,
																			item?.approveByDepartmentAt,
																			item?.userDepartment
																		)}
																	</Box>
																</Flex>
															)}

															<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
																<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
																	{t('Approved By DG')}
																</Text>
																<Box ml="4">
																	{getApproveStatus(
																		item?.approveByDirectorGeneral,
																		item?.approveByDirectorGeneralAt,
																		item?.userDG
																	)}
																</Box>
															</Flex>
														</Box>
													</Box>
												</Center>
											</Container>
										</>
									</PrintLayout>
								);
							})}
						</>
					)}
				</>
			</Container>
			<LeaveRequestFilter
				callback={callbackFilter}
				isOpen={openFilter}
				onClose={() => setOpenFilter(false)}
				number={number}
				name={name}
				type={type}
				fromDate={fromDate}
				toDate={toDate}
				read={read}
				status={status}
			/>
			{openLeaveReportModal && <LeaveReportModal isOpen={true} onClose={() => setOpenLeaveReportModal(false)} />}
		</>
	);
}

export default Report;

const leaveType = [
	{ label: 'Annual leave', value: 'ANNUAL_LEAVE' },
	{ label: 'Short leave', value: 'SHORT_LEAVE' },
	{ label: 'Maternity leave', value: 'MATERNITY_LEAVE' },
	{ label: 'Sick leave', value: 'SICK_LEAVE' },
	{ label: 'Personal leave', value: 'PERSONAL_LEAVE' },
	{ label: 'Work from home', value: 'WORK_FROM_HOME' },
];
