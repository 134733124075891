import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Box, Flex, Text, Center,
    Badge, Menu, MenuButton, MenuList, MenuItem,
    useToast,
    Wrap,
    WrapItem,
    Avatar,
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Tooltip
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import { faEllipsisV, faPrint, faPlusSquare, faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import c from "../../constant"
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from "moment"
import './style.css'
import { useLocation } from "react-router-dom";
import ViewComment from "../../components/Comment/ViewComment"
import useStore from "../../store";
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import PrintLayout from "../../components/Print/PrintLayout";
import SelectUserModal from "../../components/Modal/SelectUserModal"
import DownloadAndViewButton from '../../components/Files/DownloadAndViewButton';

const leftLabelWidth = "160px"
const marginTopRow = 1
const paddingTopRow = 1
const paddingBottomRow = 1
const QUERY_DETAIL = gql`
query get($id: String!){
    getLeavesAdjustmentById(id:$id){
        id
        type
        amount
        year
        file
        fileSize
        createdAt
        description
        creator{
          id
          username
          fullName
          fullNameKhmer
        }
        user{
          id
          username
          fullName
          fullNameKhmer
        }
      }
  }
`

const APPROVE_MUTATION = gql`
mutation approve($id: String!){
    approveLeaveRequest(id:$id)
  }
`

const REJECT_MUTATION = gql`
mutation reject($id: String!){
    rejectLeaveRequest(id:$id)
  }
`

const MUTATION_READ_NOTIFICATION = gql`
    mutation read($id:String!){
        readNotificationById(id:$id)
    }
`

function ReportDetail() {
    const { t } = useTranslation();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const id = urlParams.get('id') ? urlParams.get('id') : null;
    const { currentLang } = useStore((state) => state.currentLang);
    moment.locale(currentLang === "kh" ? "km" : "en-gb")
    const [loadDetail, { error, loading, data }] = useLazyQuery(QUERY_DETAIL)
    const { currentUser } = useStore((state) => state.currentUser);
    const toast = useToast()
    const { countUnreadNotifications } = useStore((state) => state.countUnreadNotifications);
	const setCountUnreadNotifications = useStore((state) => state.setCountUnreadNotifications);
    const [approveLeave, { error:errorApproveLeave, loading:loadingApproveLeave, data:dataApproveLeave }] = useMutation(APPROVE_MUTATION)
    const [rejectLeave, { error:errorRejectLeave, loading:loadingRejectLeave, data:dataRejectLeave }] = useMutation(REJECT_MUTATION)
    const [readNotification, {  }] = useMutation(MUTATION_READ_NOTIFICATION)
	const [showChangeFile, setShowChangeFile] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        loadDetail({
            variables: {
                id
            }
        })
    }, []);

    useEffect(() => {
        if (id) {
            loadDetail({
                variables: {
                    id
                }
            })
        }
    }, [id])

    function getDisplayName(user) {
        let result = user?.username
        if (currentLang === "kh") {
            if (result = user?.fullNameKhmer) {
                result = user?.fullNameKhmer
            } else {
                if (user?.fullName) {
                    result = user?.fullName
                } else {
                    result = user?.username
                }
            }
        } else {
            if (result = user?.fullName) {
                result = user?.fullName
            } else {
                if (user?.fullNameKhmer) {
                    result = user?.fullNameKhmer
                } else {
                    result = user?.username
                }
            }
        }

        return result
    }

    function reportType(type) {
        return (
            type === "MONTHLY" ? <Badge colorScheme="green">{t("MONTHLY")}</Badge> :
                (type === "QUARTERLY" ? <Badge colorScheme="yellow">{t("QUARTERLY")}</Badge> :
                    <Badge colorScheme="red">{t("ANNUALLY")}</Badge>)
        )
    }

    

    function getLeaveType(type){
        let res = ""
        leaveType.map(item=>{
            if(item.value===type){
                res = item.label
            }
        })
        return res
    }

    function getApproveStatus(approve,date, user){
        let res = null
        if(approve===null){
            res = <Badge colorScheme="yellow">{t("In progress")}</Badge>
        } else if (approve===true){
            res = <Badge colorScheme="green">{t("Approved Leave")}</Badge>
        } else {
            res = <Badge colorScheme="red">{t("Rejected Leave")}</Badge>
        }
        if(date){
            res = <Tooltip label={`${moment(date).format("LLLL")} ${t("By")} ${getDisplayName(user)}`}>
                {res}
            </Tooltip>
        }
        return res
    }

    useEffect(()=>{
        if (errorApproveLeave) {
            if(errorApproveLeave?.graphQLErrors?.length>0){
                errorApproveLeave?.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
            } else {
                toast({
                    title: t(errorApproveLeave?.message?errorApproveLeave?.message:"something went wrong"),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            }
        }
    },[errorApproveLeave])

    useEffect(() => {
        if (dataApproveLeave?.approveLeaveRequest) {
            toast({
                title: t("Leave request approved successfully"),
                status: "success",
                isClosable: true,
                position: "top-right"
            })
            loadDetail({
                variables: {
                    id
                }
            })
        }
    }, [dataApproveLeave])

    useEffect(()=>{
        if (errorRejectLeave) {
            if(errorRejectLeave?.graphQLErrors?.length>0){
                errorRejectLeave?.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
            } else {
                toast({
                    title: t(errorRejectLeave?.message?errorRejectLeave?.message:"something went wrong"),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            }
        }
    },[errorRejectLeave])

    useEffect(() => {
        if (dataRejectLeave?.rejectLeaveRequest) {
            toast({
                title: t("Leave request rejected successfully"),
                status: "success",
                isClosable: true,
                position: "top-right"
            })
            loadDetail({
                variables: {
                    id
                }
            })
        }
    }, [dataRejectLeave])

    function getDepartmentName(dp){
        let label = ""
        c.departments.map(item=>{
            if(item.value===dp){
                label = item.label
            }
        })
        return label
    }

    return (
        <>
        <PrintLayout>
            <>
                <Container maxW="container.xl" mt="16px" mb="16px">
                    <BreadcrumbComponent
                        list={[
                            {
                                name: "Request Leave",
                                path: "/request-leave?page=1",
                            },
                            {
                                name: "Leave Adjustment",
                                path: "/leave-adjustment?page=1",
                            },
                            {
                                name: "Leave Adjustment Detail",
                                path: "#",
                            },
                            
                        ]}
                    />
                    <Center mt="5">
                        <Box
                            w="100%"
                            bg="white"
                            borderRadius={c.borderRadius}
                            border="1px solid #dbdbdb"
                            boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                            pb="32px"
                        >
                            <Flex alignItems="center" justifyContent="space-between" bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" borderBottom="1px solid #dbdbdb">
                                <Flex justifyContent="center" alignItems="center">
                                    <Text
                                        fontSize="x-large"
                                        fontWeight="bold"
                                        mr="4"
                                    >
                                        {t("Leave Adjustment Detail")}
                                    </Text>
                                    </Flex>
                                <Flex className="hidden-print">
                                    <Flex onClick={() => window?.print()} mr="2" cursor="pointer">
                                        <FontAwesomeIcon icon={faPrint} style={{ fontSize: 16, marginTop: 4, marginLeft: 6 }} />
                                    </Flex>
                                    {(currentUser?.role==="admin"||currentUser?.canApproveRejectLeaveRequest)&&<Menu>
                                        <MenuButton cursor="pointer">
                                            <Flex>
                                                <FontAwesomeIcon icon={faEllipsisV} style={{ fontSize: 16, marginTop: 4, marginLeft: 6 }} />
                                            </Flex>
                                        </MenuButton>
                                        
                                        <MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
                                                <MenuItem icon={<CheckIcon />} color="green" onClick={() => approveLeave({variables:{id}})}>{t("Approve Leave")}</MenuItem>
                                                <MenuItem icon={<CloseIcon />} color="red" onClick={() => rejectLeave({variables:{id}})}>{t("Reject Leave")}</MenuItem>
                                        </MenuList>
                                        
                                    </Menu>}
                                    

                                </Flex>
                            </Flex>

                            {loading && <Text ml="8">{t("Loading")}...</Text>}

                            {(!error && data?.getLeavesAdjustmentById?.id) &&
                                <Box p="16px" className="leave-detail-container">
                                    <Flex pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Name")}</Text>
                                        <Text ml="4">{getDisplayName(data?.getLeavesAdjustmentById?.user)}</Text>
                                    </Flex>
                                    <Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Leave Type")}</Text>
                                        <Text ml="4">{t(getLeaveType(data?.getLeavesAdjustmentById?.type))}</Text>
                                    </Flex>
                                    <Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Created At")}</Text>
                                        <Text ml="4">{moment(data?.getLeavesAdjustmentById?.createdAt).format("LLLL")}</Text>
                                    </Flex>
                                    <Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Amount (Days)")}</Text>
                                        <Text ml="4">{data?.getLeavesAdjustmentById?.amount}</Text>
                                    </Flex>
                                    <Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Year")}</Text>
                                        <Text ml="4">{data?.getLeavesAdjustmentById?.year}</Text>
                                    </Flex>
                                    <Flex pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Created By")}</Text>
                                        <Text ml="4">{getDisplayName(data?.getLeavesAdjustmentById?.creator)}</Text>
                                    </Flex>

                                    <Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Description")}</Text>
                                        <Text ml="4">{data?.getLeavesAdjustmentById?.description}</Text>
                                    </Flex>

                                    {data?.getLeavesAdjustmentById?.file && (
										<Flex alignItems="center" mt="2">
											<Text
												fontWeight="bold"
												minW={leftLabelWidth}
												w={leftLabelWidth}
											>
												{t('File')}
											</Text>
											<DownloadAndViewButton
												url={data?.getLeavesAdjustmentById?.file}
												size={data?.getLeavesAdjustmentById?.fileSize}
											/>
										</Flex>
                                    )}
                                </Box>
                            }
                        </Box>
                    </Center>
                    {
                        (!error && data?.getLeavesAdjustmentById?.id) &&
                        <ViewComment id={data?.getLeavesAdjustmentById?.id} type="LEAVE_ADJUSTMENT" />
                    }
                </Container>
            </>
        </PrintLayout>
        </>
    );
}

export default ReportDetail;


const leaveType = [
    {label:"Annual leave", value:"ANNUAL_LEAVE"},
    {label:"Short leave", value:"SHORT_LEAVE"},
    {label:"Maternity leave", value:"MATERNITY_LEAVE"},
    {label:"Sick leave", value:"SICK_LEAVE"},
    {label:"Personal leave", value:"PERSONAL_LEAVE"},
    {label:"Work from home", value:"WORK_FROM_HOME"},
]
