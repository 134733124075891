/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Button,
	useToast,
	useDisclosure,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	AlertDialogFooter,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import InputNumberFormField from '../../components/form/InputNumberFormField';
import TextAreaFormField from '../../components/form/TextAreaFormField';
import RadioFormField from '../../components/form/RadioFormField';
import FileFormField from '../../components/form/FileFormField';
import DatetimeFormField from '../../components/form/DatetimeFormField';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import LoadingOverlayText from '../../components/LoadingOverlay/LoadingOverlayText';
import * as Yup from 'yup';
import c from '../../constant';
import moment from 'moment';
import 'moment/locale/en-gb';

const validationSchema = Yup.object().shape({
	type: Yup.string().required('required'),
	leaveAmount: Yup.mixed().test('leaveAmount', 'invalid', (value) => value % 0.5 === 0),
	leaveFromDate: Yup.string().required('required'),
	leaveToDate: Yup.string().required('required'),
	leaveComebackDate: Yup.string().required('required'),
	purpose: Yup.string(),
	attachmentFile: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize / 20;
		} else {
			return true;
		}
	}),
});

const CREATE_MUTATION = gql`
	mutation create(
		$type: String!
		$purpose: String!
		$from: DateTime!
		$to: DateTime!
		$comeback: DateTime!
		$amount: Float!
		$attachmentFile: Upload
		$attachmentFileSize: Float
	) {
		createLeave(
			leaveInput: { type: $type, purpose: $purpose, from: $from, to: $to, comeback: $comeback, amount: $amount }
			attachmentFile: $attachmentFile
			attachmentFileSize: $attachmentFileSize
		)
	}
`;

const QUERY_LEAVE_LEFT = gql`
	query {
		getLeaveAmountLeft {
			annualLeave
			shortLeave
		}
	}
`;

function AddLeaveRequest() {
	moment.locale('en-gb');

	const cancelRef = React.useRef();
	const history = useHistory();
	const toast = useToast();
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [createMutation, { loading, error, data }] = useMutation(CREATE_MUTATION);
	const [queryLeaveLeft, { data: dataLeaveLeft }] = useLazyQuery(QUERY_LEAVE_LEFT);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		queryLeaveLeft();
	}, []);

	useEffect(() => {
		if (data?.createLeave) {
			toast({
				title: t('Leave created successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			history.push('/request-leave');
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	async function submit(values) {
		const yearFrom = moment(values?.leaveFromDate).year();
		const yearTo = moment(values?.leaveToDate).year();
		if (yearFrom !== yearTo) {
			onOpen();
		} else {
			const variables = {
				type: values?.type,
				purpose: values?.purpose || 'N/A',
				from: moment(values?.leaveFromDate).toDate(),
				to: moment(values?.leaveToDate).toDate(),
				comeback: moment(values?.leaveComebackDate).toDate(),
				amount: parseFloat(values?.leaveAmount),
				attachmentFile: values?.attachmentFile ? values?.attachmentFile : null,
				attachmentFileSize: values?.attachmentFile?.size ? values?.attachmentFile?.size : null,
			};
			createMutation({ variables });
		}
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Request Leave',
							path: '/request-leave?page=1',
						},
						{
							name: 'Request',
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="0px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<Text fontSize="x-large" fontWeight="bold">
								{t('Request Leave')}
							</Text>
						</Flex>
						<Box p="16px" maxW="480px">
							<Formik
								initialValues={{
									type: '',
									leaveAmount: 1,
									leaveFromDate: new Date(),
									leaveToDate: new Date(),
									leaveComebackDate: new Date(),
									purpose: '',
									attachmentFile: null,
								}}
								validationSchema={validationSchema}
								onSubmit={submit}
							>
								{({ values, ...props }) => {
									return (
										<Box>
											<Form>
												<RadioFormField
													name="type"
													label="Type"
													radios={[
														{
															label: 'Annual leave',
															secondLabel: ` (${
																dataLeaveLeft?.getLeaveAmountLeft?.annualLeave
																	? dataLeaveLeft?.getLeaveAmountLeft?.annualLeave
																	: '0'
															})`,
															value: 'ANNUAL_LEAVE',
														},
														{
															label: 'Short leave',
															secondLabel: ` (${
																dataLeaveLeft?.getLeaveAmountLeft?.shortLeave
																	? dataLeaveLeft?.getLeaveAmountLeft?.shortLeave
																	: '0'
															})`,
															value: 'SHORT_LEAVE',
														},
														// { label: 'Maternity leave', value: 'MATERNITY_LEAVE' },
														// { label: 'Sick leave', value: 'SICK_LEAVE' },
														// { label: 'Personal leave', value: 'PERSONAL_LEAVE' },
														/*
														 * { label: 'Work from home', value: 'WORK_FROM_HOME' }
														 */
													]}
													required
												/>
												<InputNumberFormField
													{...props}
													label="Leave amount (Days)"
													name="leaveAmount"
													min={0.5}
													max={15}
													step={0.5}
													precision={1}
													required
												/>
												<DatetimeFormField {...props} label="Leave from date" name="leaveFromDate" required />
												<DatetimeFormField {...props} label="Leave to date" name="leaveToDate" required />
												<DatetimeFormField {...props} label="Leave comeback date" name="leaveComebackDate" required />
												<TextAreaFormField {...props} label="Purpose" name="purpose" />
												<>
													{values?.type && (
														<>
															<FileFormField
																{...props}
																label="Attachment File"
																name="attachmentFile"
																maxFileSize={c.maxFileSize / 20}
															/>
														</>
													)}
												</>
												<Button
													id="login-btn"
													variant="solid"
													size="md"
													h="42px"
													w="128px"
													type="submit"
													borderRadius={c.borderRadius}
													isLoading={loading}
												>
													{t('Submit')}
												</Button>
											</Form>
										</Box>
									);
								}}
							</Formik>
						</Box>
					</Box>
				</Center>
			</Container>
			<AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
				<AlertDialogOverlay />
				<AlertDialogContent>
					<AlertDialogHeader>{t('Request Leave')}</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>
						{t('Please input')} "{t('Leave from date')}" - "{t('Leave to date')}" {t('in the same year Or you can submit 2 request')}
					</AlertDialogBody>
					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose}>
							{t('Okay')}
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
			{loading && <LoadingOverlayText />}
		</>
	);
}

export default AddLeaveRequest;
