import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Flex,
    Button,
    useToast
} from "@chakra-ui/react";
import c from "../../constant"
import { gql, useLazyQuery } from '@apollo/client';
import './style.css'
import { useTranslation } from "react-i18next";
import DateRangeFormField from "../../components/form/DateRangeFormField";
import ReactExport from "react-export-excel";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";
import 'moment/locale/en-gb';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const filterSchema = Yup.object().shape({
    dateRange: Yup.string().required("Required"),
});

const QUERY_DATA = gql`
  query getLeaveReport(
        $fromDate: DateTime
        $toDate: DateTime
    ){
        getLeaveReport(
        fromDate: $fromDate
        toDate: $toDate
    ){
        id
        no
        from
        to
        comeback
        purpose
        amount
        createdBy
        createdAt
        from
        to

        approveByOffice
        approveByOfficeBy
        approveByOfficeAt

        approveByDepartment
        approveByDepartmentBy
        approveByDepartmentAt

        approveByDirectorGeneral
        approveByDirectorGeneralBy
        approveByDirectorGeneralAt
        type
        user{
            username
            fullName
            fullNameKhmer
            avatar
            department
        }
        userOffice{
            username
            fullName
            fullNameKhmer
        }
        userDepartment{
            username
            fullName
            fullNameKhmer
        }
        userDG{
            username
            fullName
            fullNameKhmer
        }
        notification{
            id
            read
        }
    }
  }
`


const QUERY_LEAVE_LEFT_DATA = gql`
  query getLeaveLeftReport(
        $fromDate: DateTime
        $toDate: DateTime
    ){
        getLeaveLeftReport(
        fromDate: $fromDate
        toDate: $toDate
    ){
        id
        khmerName
        latinName
        department
        active
        leaveLeft{
          shortLeave
          annualLeave
        }
    }
  }
`


function LeaveReportModal({
    isOpen = false, onClose = () => null, callback = () => null, role = ""
}) {
    moment.locale("en-gb")

    const { t } = useTranslation();
    const [loadData, { error, loading, data }] = useLazyQuery(QUERY_DATA)
    const [loadLeaveLeftData, { error: errorLeaveLeft, loading: loadingLeaveLeft, data: dataLeaveLeft }] = useLazyQuery(QUERY_LEAVE_LEFT_DATA)


    const toast = useToast()

    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)

    useEffect(() => {
        if (isOpen) {

        }
    }, [isOpen])

    function formatDate(val) {
        return moment(val).format("LLLL")
    }

    useEffect(() => {
        if (error) {
            if (error?.graphQLErrors?.length > 0) {
                error?.graphQLErrors.map(({ message }, i) => (
                    toast({
                        title: t(message),
                        status: "error",
                        isClosable: true,
                        position: "bottom-right"
                    })
                ))
            } else {
                toast({
                    title: t(error?.message ? error?.message : "something went wrong"),
                    status: "error",
                    isClosable: true,
                    position: "bottom-right"
                })
            }
        }
    }, [error])

    function getDepartment(val) {
        let res = ""
        c.departments.map((item) => {
            if (item.value === val) {
                res = item?.label
            }
        })
        return res
    }

    function checkIfDeputyDirector(department) {
        const dp = department?.split(",")
        if (dp?.length > 1) {
            return true
        } else {
            return false
        }
    }

    function getDisplayName(user) {
        let currentLang = "kh"
        let displayName = user?.username
        if (currentLang === "kh") {
            displayName = user?.fullNameKhmer ? user?.fullNameKhmer : (user?.fullName ? user?.fullName : user?.username)
        } else {
            displayName = user?.fullName ? user?.fullName : (user?.fullNameKhmer ? user?.fullNameKhmer : user?.username)
        }
        return displayName
    }

    useEffect(() => {
        if (data) {
            let tmpData = []
            data?.getLeaveReport.map((item) => {
                tmpData.push({
                    number: item?.no,
                    username: item?.user?.username,
                    khmerName: item?.user?.fullNameKhmer,
                    latinName: item?.user?.fullName,
                    department: (getDepartment(item?.user?.department)),
                    leaveType: item?.type,
                    leaveAmount: item?.amount,
                    createdAt: formatDate(item?.createdAt),
                    from: formatDate(item?.from),
                    to: formatDate(item?.to),
                    comebackAt: formatDate(item?.comeback),
                    purpose: item?.purpose,

                    approveByOfficeBy: checkIfDeputyDirector(item?.user?.department) ? null : (getDisplayName(item?.userOffice)),
                    approveByOfficeAt: checkIfDeputyDirector(item?.user?.department) ? null : (formatDate(item?.approveByOfficeAt)),


                    approveByDepartmentBy: checkIfDeputyDirector(item?.user?.department) ? null : (getDisplayName(item?.userDepartment)),
                    approveByDepartmentAt: checkIfDeputyDirector(item?.user?.department) ? null : (formatDate(item?.approveByDepartmentAt)),

                    approvedByDirectorGeneralBy: getDisplayName(item?.userDG),
                    approvedByDirectorGeneralAt: formatDate(item?.approveByDirectorGeneralAt),
                })
            })
            setDataSet(tmpData)

            summaryDataset(tmpData)

        }
    }, [data])

    useEffect(() => {
        if (dataLeaveLeft) {
            let tmpDataSetLeaveLeft = []
            dataLeaveLeft?.getLeaveLeftReport?.map((item) => {
                tmpDataSetLeaveLeft.push({
                    id: item.id,
                    khmerName: item?.khmerName,
                    latinName: item?.latinName,
                    department: (getDepartment(item?.department)),
                    active: item?.active,
                    annualLeave: item?.leaveLeft?.annualLeave,
                    shortLeave: item?.leaveLeft?.shortLeave,
                })
            })
            tmpDataSetLeaveLeft = tmpDataSetLeaveLeft.sort(function (a, b) {
                var textA = a.department.toUpperCase();
                var textB = b.department.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            setLeaveLeftDataSet(tmpDataSetLeaveLeft)
        }
    }, [dataLeaveLeft])

    function summaryDataset(data) {
        let users = []

        data?.map((item) => {
            users.push(item?.username)
        })

        let uniqueUsers = users.filter(function (item, pos) {
            return users.indexOf(item) == pos;
        })

        let tmpDataSetSum = []

        uniqueUsers.map((username) => {
            let totalLeave = 0
            let khmerName = ""
            let latinName = ""
            let department = ""
            let detailLeave = []
            typeOfLeave.map((item) => {
                detailLeave.push({ label: item, amount: 0 })
            })
            data.map((leave) => {
                if (username === leave.username) {
                    totalLeave = totalLeave + leave.leaveAmount
                    khmerName = leave.khmerName
                    latinName = leave.latinName
                    department = leave.department
                    detailLeave.map((dl, index) => {
                        if (leave.leaveType === dl.label) {
                            detailLeave[index].amount = detailLeave[index].amount + leave.leaveAmount
                        }
                    })

                }
            })
            let tmpAddDetail = { khmerName, latinName, department, totalLeave }
            detailLeave.map((item) => {
                tmpAddDetail[item.label] = item.amount
            })
            tmpDataSetSum.push(tmpAddDetail)
        })


        tmpDataSetSum = tmpDataSetSum.sort(function (a, b) {
            var textA = a.department.toUpperCase();
            var textB = b.department.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        setSumDataSet(tmpDataSetSum)
    }

    const [dataSet, setDataSet] = useState([])
    const [sumDataSet, setSumDataSet] = useState([])
    const [leaveLeftDataSet, setLeaveLeftDataSet] = useState([])



    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                onClose()
            }}
            size="md"
        >
            <ModalOverlay />
            <ModalContent borderRadius={c.borderRadius}>
                <ModalHeader>{t("Leave Report")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box w="100%" p="16px">
                        <Formik
                            initialValues={{
                                dateRange: "",
                            }}
                            validationSchema={filterSchema}
                            onSubmit={(values) => {
                                if (values.dateRange) {
                                    setDataSet([])
                                    setSumDataSet([])
                                    const tmpDate = JSON.parse(values.dateRange)
                                    if (tmpDate?.from && tmpDate?.to) {
                                        const dateFrom = moment({
                                            year: tmpDate.from.year,
                                            day: tmpDate.from.day,
                                            month: tmpDate.from.month - 1
                                        }).toDate()
                                        const dateTo = moment({
                                            year: tmpDate.to.year,
                                            day: tmpDate.to.day,
                                            month: tmpDate.to.month - 1
                                        }).toDate()

                                        loadData({
                                            variables: {
                                                offset: 0,
                                                fromDate: dateFrom,
                                                toDate: dateTo,
                                                status: "done"
                                            }
                                        })

                                        loadLeaveLeftData({
                                            variables: {
                                                fromDate: dateFrom,
                                                toDate: dateTo,
                                            }
                                        })

                                        setFromDate(dateFrom)
                                        setToDate(dateTo)

                                    }
                                }

                            }}
                        >
                            {(props) => (
                                <Box pb="4">
                                    <Form>
                                        <DateRangeFormField
                                            {...props}
                                            label="Date Range"
                                            name="dateRange"
                                            initialDate={{}}
                                            required
                                        />
                                        <Flex mt="16px">
                                            <Button
                                                id="login-btn"
                                                variant="solid"
                                                size="md"
                                                h="42px"
                                                w="128px"
                                                type="submit"
                                                borderRadius={c.borderRadius}
                                                color="#3498db"
                                                isLoading={loading}
                                            >
                                                {t("Generate")}
                                            </Button>
                                            {(dataSet.length > 0 && leaveLeftDataSet?.length > 0) && <ExcelFile
                                                filename={`Leave ${moment(fromDate).format("DD MM YYYY")} - ${moment(toDate).format("DD MM YYYY")} Generated At ${moment().format("DD MM YYYY hh mm ss")}`}
                                                element={
                                                    <Button
                                                        variant="solid"
                                                        size="md"
                                                        h="42px"
                                                        w="128px"
                                                        type="submit"
                                                        borderRadius={c.borderRadius}
                                                        color="#3498db"
                                                        ml="2"
                                                    >
                                                        {t("Download")}
                                                    </Button>
                                                }>
                                                <ExcelSheet data={sumDataSet} name={`Summary ${moment(fromDate).format("DD MM YYYY")} - ${moment(toDate).format("DD MM YYYY")}`}>
                                                    <ExcelColumn label="Department" value="department" />
                                                    <ExcelColumn label="Khmer Name" value="khmerName" />
                                                    <ExcelColumn label="Latin Name" value="latinName" />
                                                    {
                                                        typeOfLeave.map((item, index) => <ExcelColumn key={`t-${index}`} label={item} value={item} />)
                                                    }
                                                    <ExcelColumn label="Total Leave Amount" value="totalLeave" />
                                                </ExcelSheet>
                                                <ExcelSheet data={dataSet} name={`Raw ${moment(fromDate).format("DD MM YYYY")} - ${moment(toDate).format("DD MM YYYY")}`}>
                                                    <ExcelColumn label={("Number")} value="number" />
                                                    <ExcelColumn label={("Department")} value="department" />
                                                    <ExcelColumn label={("Khmer Name")} value="khmerName" />
                                                    <ExcelColumn label={("Latin Name")} value="latinName" />
                                                    <ExcelColumn label={("Leave Type")} value="leaveType" />
                                                    <ExcelColumn label={("Leave Amount")} value="leaveAmount" />
                                                    <ExcelColumn label={("Created At")} value="createdAt" />
                                                    <ExcelColumn label={("From")} value="from" />
                                                    <ExcelColumn label={("To")} value="to" />
                                                    <ExcelColumn label={("Comeback At")} value="comebackAt" />
                                                    <ExcelColumn label={("Approve By Office")} value="approveByOfficeBy" />
                                                    <ExcelColumn label={("Approve By Office At")} value="approveByOfficeAt" />
                                                    <ExcelColumn label={("Approve By Department")} value="approveByDepartmentBy" />
                                                    <ExcelColumn label={("Approve By Department At")} value="approveByDepartmentAt" />
                                                    <ExcelColumn label={("Approve By Director General")} value="approvedByDirectorGeneralBy" />
                                                    <ExcelColumn label={("Approved By Director General At")} value="approvedByDirectorGeneralAt" />
                                                    <ExcelColumn label={("Purpose")} value="purpose" />
                                                </ExcelSheet>
                                                <ExcelSheet data={leaveLeftDataSet} name={`User Leave Left ${moment(fromDate).format("YYYY")}`}>
                                                    <ExcelColumn label="ID" value="id" />
                                                    <ExcelColumn label="Department" value="department" />
                                                    <ExcelColumn label="Khmer Name" value="khmerName" />
                                                    <ExcelColumn label="Latin Name" value="latinName" />
                                                    <ExcelColumn label="Active" value="active" />
                                                    <ExcelColumn label="Annual Leave Left" value="annualLeave" />
                                                    <ExcelColumn label="Short Leave Left" value="shortLeave" />
                                                </ExcelSheet>
                                            </ExcelFile>}
                                        </Flex>
                                    </Form>
                                </Box>
                            )}
                        </Formik>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default LeaveReportModal;


const typeOfLeave = [
    'ANNUAL_LEAVE',
    'SHORT_LEAVE',
    'MATERNITY_LEAVE',
    'SICK_LEAVE',
    'PERSONAL_LEAVE',
    'WORK_FROM_HOME'
]