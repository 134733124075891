import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Box, Flex, Text, Center, Button, useToast,
    useDisclosure,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogCloseButton,
    AlertDialogBody,
    AlertDialogFooter
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from "react-router-dom";
import DateRangeFormField from "../../components/form/DateRangeFormField";
import moment from "moment";
import 'moment/locale/en-gb';
import CompanyPickerModal from "../../components/Modal/CompanyPickerModal"
import UploadingModalProgress from "../../components/UploadingModalProgress/UploadingModalProgress"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";

import UserFormField from "../../components/form/UserFormField"
import TextFormField from "../../components/form/TextFormField"
import TextAreaFormField from "../../components/form/TextAreaFormField"
import RadioFormField from "../../components/form/RadioFormField"
import SelectFormField from "../../components/form/SelectFormField"
import FileFormField from "../../components/form/FileFormField"
import DatetimeFormField from "../../components/form/DatetimeFormField"

const MAX_FILE_SIZE = 104857600;

const complaintOnInsuranceSchema = Yup.object().shape({
    user: Yup.string().required("required"),
    leaveType: Yup.string().required("required"),
    adjustmentType: Yup.string().required("required"),
    amount: Yup.string().required("required"),
    year: Yup.string().required("required"),
    description: Yup.string()
});


const CREATE_MUTATION = gql`
    mutation create(
        $userId: String!
        $type: String!
        $amount: Float!
        $fileSize: Float!
        $file: Upload
        $year: Float!
        $description: String
    ){
        createLeaveAdjustment(
            file: $file,
            createLeaveAdjustmentInput:{
            userId: $userId
            type: $type
            amount: $amount
            fileSize: $fileSize
            year: $year
            description: $description
        })
    }
`

function AddCompanyProposal() {
    moment.locale("en-gb")
    const history = useHistory();
    const toast = useToast()
    const { t } = useTranslation();
    const [showCompanyPicker, setShowCompanyPicker] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState(null)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [type, setType] = useState("")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()  
    const [uploading, setUploading] = useState(false)
    const [progress, setProgress] = useState(0);

    const [createMutation, { loading, error, data }] = useMutation(
         CREATE_MUTATION
    );

    useEffect(()=>{
        if(data?.createLeaveAdjustment){
            setUploading(false)
            toast({
                title: t("Leave adjustment created successfully"),
                status: "success",
                isClosable: true,
                position:"top-right"
            })
            history.push("/leave-adjustment")
        }
    },[data])

    useEffect(() => {
        if (error) {
            setUploading(false)
            if(error?.graphQLErrors?.length>0){
            error?.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "bottom-right"
                })
            ))
            } else {
                toast({
                    title: t(error?.message?error?.message:"something went wrong"),
                    status: "error",
                    isClosable: true,
                    position: "bottom-right"
                })
            }
        }
    }, [error])


    async function submit(values) {
        console.log("values:", values)
        let amount = null
        if(values?.adjustmentType === "subtraction"){
            amount = -Math.abs(values?.amount)
        } else {
            amount = Math.abs(values?.amount)
        }
        if(amount!==null){
            const variables = {
                userId: values?.user,
                type: values?.leaveType,
                amount: amount,
                year: parseInt(values?.year),
                description:values?.description,
                file: values?.file,
                fileSize: values?.file?.size,
            }
            createMutation({
                variables
            })
        }
    }
    
    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: "Request Leave",
                            path: "/request-leave?page=1",
                        },
                        {
                            name: "Leave Adjustment",
                            path: "/leave-adjustment?page=1",
                        },
                        {
                            name: "Add",
                            path: "#",
                        },
                    ]}
                />
                <Center mt="5">
                    <Box
                        w="100%"
                        bg="white"
                        borderRadius={c.borderRadius}
                        border="1px solid #dbdbdb"
                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                        pb="32px"
                    >
                        <Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                            <Text
                                fontSize="x-large"
                                fontWeight="bold"
                            >
                                {t("Leave Adjustment")}
                            </Text>
                        </Flex>

                        <Box p="16px" maxW="480px">
                        <Formik
                                    initialValues={{
                                        user: "",
                                        leaveType: "",
                                        adjustmentType: "",
                                        amount: "",
                                        year: "",
                                        file: null,
                                        description: "",
                                    }}
                                    validationSchema={complaintOnInsuranceSchema}
                                    onSubmit={(values) => {
                                        submit(values)
                                    }}
                                >
                                    {(props) => (
                                        <Box>
                                            <Form>
                                                <UserFormField name="user" label="User" required viewType="all"/>
                                                
                                                <RadioFormField
                                                    name="leaveType"
                                                    label="Leave Type"
                                                    radios={[
                                                        {label:"Annual leave", value:"ANNUAL_LEAVE"},
                                                        {label:"Short leave", value:"SHORT_LEAVE"},
                                                    ]}
                                                    required
                                                    callbackValueChange={e=>setType(e)}
                                                />

                                                <RadioFormField
                                                    name="adjustmentType"
                                                    label="Type"
                                                    radios={[
                                                        {label:"Subtraction", value:"subtraction"},
                                                        {label:"Addition", value:"addition"},
                                                    ]}
                                                    required
                                                    callbackValueChange={e=>setType(e)}
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="Amount (Days)"
                                                    name="amount"
                                                    required
                                                    type="number"
                                                    min={0.5}
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="Year"
                                                    name="year"
                                                    required
                                                    type="number"
                                                    min={1}
                                                />

                                                <TextAreaFormField
                                                    {...props}
                                                    label="Description"
                                                    name="description"
                                                />

                                                <FileFormField
                                                    {...props}
                                                    label="File"
                                                    name="file"
                                                    maxFileSize={MAX_FILE_SIZE}
                                                    required
                                                />

                                                <Button
                                                    id="login-btn"
                                                    variant="solid"
                                                    size="md"
                                                    h="42px"
                                                    w="128px"
                                                    type="submit"
                                                    borderRadius={c.borderRadius}
                                                    isLoading={loading}
                                                >
                                                    {t("Submit")}
                                                </Button>

                                            </Form>

                                        </Box>
                                    )}
                                </Formik>
                        </Box>
                    </Box>
                </Center>
            </Container>

            <UploadingModalProgress isOpen={uploading} progress={progress}/>

            {showCompanyPicker&&<CompanyPickerModal onClose={()=>setShowCompanyPicker(false)} callBack={e=>setSelectedCompany(e)}/>}
            
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                <AlertDialogHeader>{t("Request Leave")}</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                    {t("Please input")} "{t("Leave from date")}" - "{t("Leave to date")}" {t("in the same month Or you can submit 2 request")}
                </AlertDialogBody>
                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                        {t("Okay")}
                    </Button>
                </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
}

export default AddCompanyProposal;

